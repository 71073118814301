import { render, staticRenderFns } from "./consultProgress.vue?vue&type=template&id=6385c63d&scoped=true"
import script from "./consultProgress.vue?vue&type=script&lang=js"
export * from "./consultProgress.vue?vue&type=script&lang=js"
import style0 from "./consultProgress.vue?vue&type=style&index=0&id=6385c63d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6385c63d",
  null
  
)

export default component.exports